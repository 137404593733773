import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import GeoContext from 'contexts/geoContext'
import { rem } from 'polished'
import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import OfferTilePricing from '../../Support/OfferTilePricing'
import SearchTileFlightOnlyPricing from '../../Support/SearchTileFlightOnlyPricing'
import SoldOutMessage from '../../Support/SoldOutMessage'
import { mediaQueryOnly, mediaQueryUp } from 'components/utils/breakpoint'
import useDefaultAirport from 'hooks/useDefaultAirport'
import { getSumExtraGuestsSurcharge } from 'lib/checkout/cartReservationUtils'
import OfferTileLoggedInButtons from '../../Support/OfferTileLoggedInButtons'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_VILLA } from 'constants/offer'
import { isNonEmptyArray } from 'lib/array/arrayUtils'
import config from 'constants/config'
import cn from 'clsx'
import { SPLIT_VIEW_BREAKPOINTS } from 'components/Pages/HotelSearchPage/HotelSplitView/constants'
import PriceRowFlightPackage from 'components/Luxkit/PricePoints/PriceRowFlightPackage'
import WalledContent from 'components/Common/WalledContent'
import { useLocation } from 'react-router'
import { useAppSelector } from 'hooks/reduxHooks'
import useOptimizelyExperiment from '../../../../../hooks/Optimizely/useOptimizelyExperiment'
import { getOptimizelyExperimentVariation } from '../../../../../lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import { safeDivideAndCeil } from 'lib/maths/mathUtils'
import { calculateDiscount } from 'lib/payment/calculateDiscount'

const PriceDetails = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: ${rem(8)};
  position: relative;
  width: auto;
  min-height: ${rem(84)};

  ${mediaQueryUp.desktop} {
    margin-left: ${rem(20)};
    margin-top: ${rem(28)};
  }
  &.landing-page-test {
    margin-top: 0;
  }
  > * + * {
    margin-top: ${rem(8)};
  }
  &.mapTile {
    margin-left: 0;
  }
`

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  flex-direction: column;
  min-width: ${rem(180)};
  align-items: flex-start;
`

const StyledSearchTileFlightOnlyPricing = styled(SearchTileFlightOnlyPricing)`
  align-items: flex-start;
  width: 100%;
`

const AlignedLoader = styled(LoadingIndicator)`
  align-items: flex-start;
`

const PriceAndButtonContainer = styled.div`
  width: 100%;
  &.mapTile {
    @media screen and (min-width: ${SPLIT_VIEW_BREAKPOINTS.medium}px) and (max-width: ${SPLIT_VIEW_BREAKPOINTS.large - 1}px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  ${mediaQueryOnly.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  > * + * {
    margin-top: ${rem(8)};
  }
`

const StyledViewOfferButton = styled(TextButton)`
  width: fit-content;
  ${mediaQueryUp.tablet} {
    width: 100%;
  }
  &.mapTile {
    @media screen and (min-width: ${SPLIT_VIEW_BREAKPOINTS.medium}px) and (max-width: ${SPLIT_VIEW_BREAKPOINTS.large - 1}px) {
      padding: 0 ${rem(20)};
      width: fit-content;
    }
  }
`

interface Props {
  offer: App.OfferSummary;
  soldOut: boolean;
  offerUrl: string;
  bestPriceForDates?: App.OfferAvailableRate;
  filters: App.OfferListFilters | undefined;
  checkInDate: moment.Moment | undefined;
  bestPricePackage?: App.Package;
  passedWalledGarden?: boolean;
  className?: string;
}

function SearchTilePriceDetails(props: Props) {
  const {
    offer,
    soldOut,
    offerUrl,
    bestPriceForDates,
    filters,
    checkInDate,
    bestPricePackage,
    passedWalledGarden = true,
    className,
  } = props
  const { offerFlightsEnabled } = offer

  const { currentRegionHasFlights } =
    useContext(GeoContext)

  const isPerNightPricingTestEnabled = !!useAppSelector((state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight))

  const slimOfferTilesVariant: undefined | boolean = useOptimizelyExperiment(OptimizelyExperiments.searchSlimOfferTiles)

  const isMapPage = useLocation().pathname.includes('/map')

  const hasDates = !!(filters?.checkIn && filters?.checkOut && isNonEmptyArray(filters?.rooms))

  const defaultAirport = useDefaultAirport()
  const flightPrice = defaultAirport?.code && offer.flightPrices[defaultAirport.code]

  const displayedPriceTotal = useMemo<undefined | number>(() => {
    if (filters?.checkIn || !flightPrice || !passedWalledGarden || !bestPricePackage) return undefined

    const bestPriceForDatesMemberPrice = bestPriceForDates?.memberPrice ?? 0
    const bestPricePackageMemberPrice = bestPricePackage?.memberPrice ?? 0
    const shouldShowPackagedMemberTotal = (bestPriceForDatesMemberPrice || bestPricePackageMemberPrice) > 0

    const packagedTotal = Math.ceil((bestPriceForDates?.price ?? (bestPricePackage.price * (filters?.rooms?.length || 1))) + getSumExtraGuestsSurcharge(filters?.rooms ?? [], offer, bestPricePackage))
    const packagedMemberTotal = Math.ceil((bestPriceForDates?.memberPrice ? bestPriceForDates?.memberPriceWithSurcharge : (bestPricePackage.memberPrice * (filters?.rooms?.length || 1))) + getSumExtraGuestsSurcharge(filters?.rooms ?? [], offer, bestPricePackage))

    const priceTotal = flightPrice + (shouldShowPackagedMemberTotal ? packagedMemberTotal : packagedTotal)
    return isPerNightPricingTestEnabled ? safeDivideAndCeil(priceTotal, (bestPriceForDates?.duration ?? filters?.durationMin ?? offer.minDuration ?? 1)) : priceTotal
  }, [bestPriceForDates?.duration, bestPriceForDates?.memberPrice, bestPriceForDates?.memberPriceWithSurcharge, bestPriceForDates?.price, bestPricePackage, filters?.checkIn, filters?.durationMin, filters?.rooms, flightPrice, offer, passedWalledGarden, isPerNightPricingTestEnabled])

  const discountPercentage = useMemo(() => {
    if (offer.isDiscountPillHidden) {
      return undefined
    }

    if (bestPricePackage) {
      return calculateDiscount(bestPricePackage.price, bestPricePackage.value)
    }
    return undefined
  }, [bestPricePackage, offer])

  const wall = <OfferTileLoggedInButtons
    signUpText="Sign up for free"
    discountPercentage={discountPercentage}
    align="left"
    showContent={offer.type === OFFER_TYPE_HOTEL || offer.type === OFFER_TYPE_VILLA}
 />

  return (
    <PriceDetails className={`${className} ${cn({ mapTile: isMapPage })}`}>
      <PriceWrapper>
        <Group
          fullWidth
          desktopDirection="vertical"
          direction="horizontal"
          verticalAlign="start"
          horizontalAlign="start"
          gap={16}
        >
          {bestPricePackage && (
            <WalledContent enforced={offer.walledGarden && !passedWalledGarden} wall={wall}>
              <Group direction="vertical" gap={8} fullWidth>
                {!offer.bundledWithFlightsOnly && (
                  <PriceAndButtonContainer className={cn({ mapTile: isMapPage })}>
                    <OfferTilePricing
                      offer={offer}
                      hasDates={hasDates}
                      checkInDate={checkInDate}
                      pkg={bestPricePackage}
                      pricing={bestPriceForDates}
                      offerUrl={offerUrl}
                      align="start"
                      filters={filters}
                    />
                    <StyledViewOfferButton className={cn({ mapTile: isMapPage })} kind="primary">View offer</StyledViewOfferButton>
                  </PriceAndButtonContainer>
                )}
                {offer.bundledWithFlightsOnly && (
                  <StyledSearchTileFlightOnlyPricing
                    offer={offer}
                    rooms={filters?.rooms}
                    hasDates={hasDates}
                    pkg={bestPricePackage}
                    pricing={bestPriceForDates}
                    offerUrl={offerUrl}
                  />
                )}
                {currentRegionHasFlights &&
                offerFlightsEnabled &&
                !offer.bundledWithFlightsOnly &&
                config.businessTraveller.currentAccountMode !== 'business' &&
                !slimOfferTilesVariant &&
                  <PriceRowFlightPackage
                    product="hotel"
                    price={displayedPriceTotal}
                    data-testid="search-tile-price-details-total"
                  />}
              </Group>
            </WalledContent>
          )}
        </Group>
      </PriceWrapper>
      {soldOut && <SoldOutMessage offer={offer} filters={filters} />}
      {!bestPricePackage && <AlignedLoader opaque floating />}
    </PriceDetails>
  )
}

export default SearchTilePriceDetails
