import React, { ComponentProps, MouseEventHandler, useCallback } from 'react'
import Caption from '../../Luxkit/Typography/Caption'
import TextLink from '../../Luxkit/TextLink'

interface Props {
  size: 'M' | 'L'
  onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  M: 'medium',
  L: 'large',
}

const SIZES_CAPTION_WEIGHTS: Record<Props['size'], ComponentProps<typeof Caption>['weight']> = {
  M: 'normal',
  L: 'bold',
}

function ExperimentalPriceRowPriceDetails(props: Props) {
  const {
    size,
    onClick,
  } = props

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()
    onClick(event)
  }, [onClick])

  return <Caption
    className={ExperimentalPriceRowPriceDetails.name}
    variant={SIZES_CAPTION_VARIANTS[size]}
    weight={SIZES_CAPTION_WEIGHTS[size]}
    colour="primary"
  >
    <TextLink onClick={handleClick}>
      Includes taxes and fees
    </TextLink>
  </Caption>
}

export default ExperimentalPriceRowPriceDetails
