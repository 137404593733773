import { Dispatch, Middleware, MiddlewareAPI } from 'redux'

import { accountAccessShowLogin } from 'actions/UiActions'
import { accountAddMobilePrompted } from 'storage/account'
import { API_CALL_SUCCESS } from 'actions/actionConstants'
import { canBroadcast, LUX_BROADCAST_CHANNEL } from 'lib/web/broadcastChannelUtils'
import { fetchAccountAgentHub as fetchAgentHubAccount, fetchAgentHubCommissionConfig } from 'actions/AgentHubActions'
import { fetchHighIntentOffers, fetchPersonalisedAlternativeOffers } from 'actions/RecommendationActions'
import { fetchPaymentCards, fetchStripePaymentCards, authLogout, fetchAvailableCredit } from 'actions/AuthActions'
import { matchPath } from 'react-router'
import { registerRequestInterceptor, unregisterRequestInterceptor, Interceptor } from 'api/requestUtils'
import { resetContext } from 'analytics/analytics'
import { signinSuccessEvent, signupSuccessEvent } from 'analytics/snowplow/events'
import * as Analytics from 'analytics/analytics'
import { USER_LOGIN, USER_REGISTRATION, USER_LOGOUT } from 'actions/apiActionConstants'
import config from 'constants/config'
import unauthorisedInterceptor from 'api/interceptors/unauthorisedInterceptor'

function setSSRAuthToken(accessToken?: string) {
  let expires = '2018'

  if (accessToken) {
    expires = '2040'
  }

  document.cookie = `ssr_access_token=${accessToken}; expires=Wed, 11 Jul ${expires} 00:00:00 GMT; path=/; domain=${document.domain}`
}

function resetSSRAuthToken() {
  document.cookie = `ssr_access_token=''; expires=Wed, 11 Jul 2018 00:00:00 GMT; path=/; domain=${document.domain}`
}

function isAddPhoneValidPage(currentUrl: string): boolean {
  const ADD_PHONE_MODAL_VALID_ROUTES = [
    '/:regionCode',
    '/:regionCode/search',
    '/:regionCode/search/homes-and-villas',
    '/:regionCode/search/tours',
    '/:regionCode/search/cruises',
    '/:regionCode/search/car-hire',
    '/:regionCode/search/experiences',
    '/:regionCode/flights-search-results',
  ]

  return ADD_PHONE_MODAL_VALID_ROUTES.some(path => matchPath(currentUrl, { path, exact: true }))
}

function promptAddPhone(action, api: MiddlewareAPI<Dispatch<any>, App.State>) {
  const appState:App.State = api.getState()
  return !appState.config.headlessMode &&
    !config.agentHub.isEnabled &&
    !action.data.isSpoofed &&
    !action.data.isSignUp &&
    !action.data.account.phone &&
    !accountAddMobilePrompted() &&
    isAddPhoneValidPage(appState.router.location.pathname)
}

let interceptor: Interceptor
const userMiddleware: Middleware<{}, App.State, Dispatch<any>> = (api) => next => (action) => {
  if ((action.api === USER_LOGIN || action.api === USER_REGISTRATION) &&
         action.type === API_CALL_SUCCESS) {
    // by running next, the rest of the reducer flow goes through
    // and we now have the state "after" the dispatch is done
    next(action)

    // The fetching below relies on the account being populated in the reducer
    api.dispatch(fetchAvailableCredit())
    api.dispatch(fetchPaymentCards())
    api.dispatch(fetchStripePaymentCards())
    api.dispatch(fetchHighIntentOffers())
    api.dispatch(fetchPersonalisedAlternativeOffers(api.getState().geo.currentRegionCode))

    if (config.agentHub.isEnabled) {
      api.dispatch(fetchAgentHubAccount())
      api.dispatch(fetchAgentHubCommissionConfig())
    }

    setSSRAuthToken(action.data.accessToken)
    // always clean up any previous interceptors
    unregisterRequestInterceptor(interceptor)
    // log them out if an api returns a 401, probably means their connection is no longer authorised!
    // when we unregister need it to be the same function, so save the one we registered with here
    interceptor = unauthorisedInterceptor(() => api.dispatch(authLogout()))
    registerRequestInterceptor(interceptor)

    if (canBroadcast() && !action.supressBroadcast) {
      const channel = new BroadcastChannel(LUX_BROADCAST_CHANNEL)
      // we just logged in, let other tabs/windows know about it
      channel.postMessage({ action })
      channel.close()
    }
    // If it's a signup and not an action from another tab/window
    if (action.data.isSignUp && !action.supressBroadcast) {
      Analytics.trackEvent(signupSuccessEvent(action.source))
    }

    // If it's a signin and not an action from another tab/window
    if (action.data.isSignIn && !action.supressBroadcast) {
      Analytics.trackEvent(signinSuccessEvent(action.source))
    }

    // prompt for adding mobile if they don't have one
    // added here so it also picks up social logins
    if (promptAddPhone(action, api)) {
      api.dispatch(accountAccessShowLogin('promptAddPhone'))
    }
    return
  } else if (action.api === USER_LOGOUT && action.type === API_CALL_SUCCESS) {
    unregisterRequestInterceptor(interceptor)
    resetSSRAuthToken()

    if (canBroadcast() && !action.supressBroadcast) {
      const channel = new BroadcastChannel(LUX_BROADCAST_CHANNEL)
      // we just logged out, let other tabs/windows know about it
      channel.postMessage({ action })
      channel.close()
    }
    resetContext()
  }

  return next(action)
}

export default userMiddleware
