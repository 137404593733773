import React, { useCallback, useContext } from 'react'
import Clickable from '../Clickable'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import PriceRowPriceDetails from 'components/Luxkit/PricePoints/PriceRowPriceDetails'
import ModalContext from 'contexts/ModalContext'
import PriceDetailsModal from './PriceDetailsModal'
import ExperimentalPriceRowPriceDetails from '../../Search/Hotels/ExperimentalPriceRowPriceDetails'

type Props = {
  offerPage?: boolean;
  offer: App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary;
  duration: number;
  taxesAndFees?: number;
  propertyFees?: number;
  hotelPrice: number;
  hotelMemberPrice?: number;
  showMemberPrice?: boolean;
  flightPrice?: number;
  otherFees?: any
  rooms?: Array<App.Occupants>;
  extraGuestMessage?: string;
  dueAtPropertyMessage?: string;
  propertiesFees?: Array<App.PropertyFeesDetails>;
  cta?: React.ReactNode;
  onCtaAfterClose?: () => void;
  slimTileVariant?: boolean;
} & ({
  trigger: 'price-row'
  triggerSize: 'M' | 'L'
} | {
  trigger: 'info-icon'
  triggerSize?: undefined
})

function OfferPriceDetailsRow(props: Props) {
  const {
    trigger,
    triggerSize,
    slimTileVariant,
    ...modalProps
  } = props

  const showModal = useContext(ModalContext)

  const openModal = useCallback(async() => {
    const result = await showModal<'fine-print' | undefined>(<PriceDetailsModal
        {...modalProps}
      />,
    )

    if (result === 'fine-print') {
      window.location.href = '#fine-print'
    }
  }, [modalProps, showModal])

  return (
    <>
      {trigger === 'price-row' && slimTileVariant && <ExperimentalPriceRowPriceDetails size={triggerSize} onClick={openModal} />}
      {trigger === 'price-row' && !slimTileVariant && <PriceRowPriceDetails size={triggerSize} onClick={openModal} />}
      {trigger === 'info-icon' && <Clickable onClick={openModal}><SolidInfoCircleIcon /></Clickable>}
    </>
  )
}

export default OfferPriceDetailsRow
