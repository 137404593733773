import React from 'react'
import { searchShowOnMapClick } from 'analytics/eventDefinitions'
import { addGTMEvent } from 'api/googleTagManager'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import SearchTargetDistance from '../../Support/SearchTargetDistance'
import TextLink from 'components/Luxkit/TextLink'
import Group from 'components/utils/Group'
import CaptionTextBlock from 'components/Luxkit/TextBlocks/CaptionTextBlock'

interface Props {
  location: string;
  shouldShowMapLink?: boolean;
  mapSearchUrl?: string;
  searchTargetLabel?: string;
  searchTargetDistance?: number;
  shouldShowSearchDistance?: boolean;
  slimOfferTile?: boolean; // A/B test
}

function OfferTileLocationSection(props: Props) {
  const { location, shouldShowMapLink = false, mapSearchUrl, searchTargetDistance, searchTargetLabel, shouldShowSearchDistance = true, slimOfferTile = false } = props
  const showOnMapText = 'Show on map'
  const showSearchDistance = shouldShowSearchDistance && !!searchTargetLabel && typeof searchTargetDistance === 'number' && searchTargetDistance > 0

  return (
    <Group direction="horizontal" gap={8} verticalAlign="center">
      <CaptionTextBlock variant="large" startIcon={!slimOfferTile && <LineMapMarkerIcon />} >
        {location}
      </CaptionTextBlock>
      {shouldShowMapLink && !!mapSearchUrl && <><div>·</div>
        <TextLink to={mapSearchUrl} onClick={() => addGTMEvent(searchShowOnMapClick())} size={slimOfferTile ? 'small' : 'medium'} weight={slimOfferTile ? 'regular' : 'bold'} >{showOnMapText}</TextLink>
      </>}
      {showSearchDistance && !!searchTargetLabel && searchTargetDistance && <SearchTargetDistance searchTargetLabel={searchTargetLabel} distance={searchTargetDistance}/>}
    </Group>
  )
}

export default OfferTileLocationSection
