import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import useImpressionHandler from 'hooks/useImpressionHandler'
import moment from 'moment'
import React, { useCallback, useContext, useMemo } from 'react'
import SearchTilePriceDetails from '../TileSections/SearchTilePriceDetails'
import { Main, OfferOverlayContainer, StyledOfferBadge, DetailSegment, MainImageSegment } from '../SearchTileStyles'
import SearchRentalPropertyDetails from '../SearchVillaTile/SearchVillaPropertyDetails'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import cn from 'clsx'
import { ImageParams } from 'components/Common/ResponsiveImage'
import { useLocation } from 'react-router'
import CSSBreakpoint from '../../../../utils/CSSBreakpoint'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import styled from 'styled-components'
import Group from '../../../../utils/Group'
import OfferTileLocationSection from '../TileSections/OfferTileLocationSection'
import NumberRating from '../../../../Common/NumberRating'
import OfferTilePropertyHeading from '../TileSections/OfferTilePropertyHeading'
import { connect } from 'react-redux'
import { setSearchParamValue } from '../../../../../lib/url/searchUrlUtils'
import { queryKeySelectedOfferIds } from '../../../../../constants/url'
import { encodeOfferIds } from 'lib/search/searchUtils'

const imageParams: ImageParams = {
  tabletAspectRatio: '16:9',
  tabletWidth: '100vw',
  desktopAspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopAspectRatio: '16:9',
  largeDesktopWidth: '762px',
  quality: 'good',
}

const StyledHeadingAndRating = styled(Group)`
  grid-row-start: 1;
  grid-column-start: span 2;
`

interface MappedProps {
  windowSearch: string;
}

interface Props {
  offer: App.VillaOffer;
  bestPriceForDates?: App.OfferAvailableRate;
  filters?: App.OfferListFilters;
  offerUrl: string;
  bestPricePackage?: App.Package;
  onImageChange?: (idx: number, image?: App.Image) => void
}

function SearchVillaTileExpandedSlim(props: Props & MappedProps) {
  const {
    offer,
    bestPriceForDates,
    filters,
    offerUrl,
    bestPricePackage,
    windowSearch,
    onImageChange,
  } = props

  const { locationHeading, locationSubheading, badge, images: offerImages } = offer
  const images = offerImages
  const location = useMemo(() => [locationHeading, locationSubheading].filter(t => t).join(', '), [locationHeading, locationSubheading])
  const impressionRef = useImpressionHandler(offer.id)
  const cancellationPolicyType = offer.lowestPricePackage?.roomRate?.cancellationPolicy.type

  const isMapPage = useLocation().pathname.includes('map')

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    onEvent(OfferListEvents.offerReady, {
      available: !!bestPriceForDates,
    })
  }, [onEvent, bestPriceForDates])

  const mapSearchUrl = `/search/map?${setSearchParamValue(
    windowSearch,
    queryKeySelectedOfferIds,
    encodeOfferIds([offer]),
  )}`

  return (
    <Main ref={impressionRef}>
      <MainImageSegment>
        <ImageCarousel
          images={images}
          imageParams={imageParams}
          onImageChange={onImageChange}
          onImageLoad={handleImageLoaded}
        />
        <OfferOverlayContainer className={cn({ 'slim-rental': true })}>
          <>
            <CSSBreakpoint max="mobile">
              <BookmarkButton offer={offer}/>
            </CSSBreakpoint>
            <CSSBreakpoint min="tablet">
              <FloatingBookmarkContainer className="slim-tile">
                <BookmarkButton offer={offer}/>
              </FloatingBookmarkContainer>
            </CSSBreakpoint>
          </>
        </OfferOverlayContainer>
        {badge && <StyledOfferBadge badge={badge} dimensions={{ width: 86, height: 86 }} />}
      </MainImageSegment>
      <div>
        <DetailSegment className={cn({ mapTile: isMapPage, slimOfferTileVariant: true })}>
          <StyledHeadingAndRating direction="horizontal" verticalAlign="start" gap={16} horizontalAlign="space-between">
            <Group direction="vertical">
              <OfferTileLocationSection
                location={location}
                mapSearchUrl={mapSearchUrl}
                shouldShowMapLink
                slimOfferTile
              />
              {offer.property && <OfferTilePropertyHeading name={offer.property.name} />}
            </Group>
            <CSSBreakpoint max="mobile">
              {offer.property && <NumberRating rating={offer?.property?.rating ?? 0} total={offer.property.reviewsTotal} variant="small" direction="horizontal-reverse" onlyNumber />}
            </CSSBreakpoint>
            <CSSBreakpoint min="tablet">
              {offer.property && <NumberRating rating={offer?.property?.rating ?? 0} total={offer.property.reviewsTotal} variant="small" direction="horizontal-reverse" />}
            </CSSBreakpoint>
          </StyledHeadingAndRating>
          <SearchRentalPropertyDetails
            offer={offer}
            location={location}
            cancellationPolicyType={cancellationPolicyType}
            filters={filters}
            offerUrl={offerUrl}
          />
          <SearchTilePriceDetails
            offer={offer}
            filters={filters}
            soldOut={false}
            offerUrl={offerUrl}
            bestPriceForDates={bestPriceForDates}
            bestPricePackage={bestPricePackage}
            checkInDate={moment(filters?.checkIn)}
            />
        </DetailSegment>
      </div>
    </Main>
  )
}

const mapStateToProps = (state: App.State): MappedProps => ({
  windowSearch: state.router.location.search,
})

export default connect(mapStateToProps)(SearchVillaTileExpandedSlim)
