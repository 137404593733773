/** This is a generic search tile which are used on
 *  Last Minute and Tactical Always On as the designs are same
 *  This tile will first check whether (1) the search performed are property search and (2) the result has been soldout
 *  Then it will render either the (1) Expanded or (2) Condensed search results.
 * */

import React, { useMemo, useEffect, useContext, useCallback, useState } from 'react'
import SearchOfferTileCondensed from './SearchOfferTileCondensed'
import styled from 'styled-components'
import { getPlural } from 'lib/string/pluralize'
import { sortBy } from 'lib/array/arrayUtils'
import { OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import Pane from 'components/Common/Pane'
import HighlightMessage from 'components/Common/HighlightMessage/HighlightMessage'

import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { fetchBestPriceForOffer } from 'actions/OfferActions'
import useImpressionHandler from 'hooks/useImpressionHandler'

import { buildSearchParamsKey } from 'lib/search/searchUtils'
import { hasPassedWalledGarden } from 'selectors/accountSelectors'
import SearchOfferTileLoadingSkeleton from './SearchOfferTileLoadingSkeleton'
import SearchOfferTileExpanded from './SearchOfferTileExpanded'
import { isSpoofed } from 'selectors/featuresSelectors'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { useOfferSoldOutPushDown } from 'hooks/Offers/useOfferSoldOutPushDown'
import useSearchTileInclusionUpsellPackage from 'hooks/useSearchTileInclusionUpsellPackage'
import { logNewRelicError } from 'services/newRelic'
import useOffer from 'hooks/Offers/useOffer'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import SearchOfferTileExpandedSlim from './SlimSearchTiles/SearchOfferTileExpandedSlim'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Root = styled(Pane)`
  position: relative;
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  offerMetaData?: App.OfferListMetaData;
  bestPrices?: {
    [key: string]: App.OfferMaybeAvailableRate;
  };
  pricesErrors?: { [key: string]: any };
  passedWalledGarden: boolean;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl?: string;
  offerLinkIncludesFilters?: boolean;
  isSpoofed?: boolean;
  currentRegion?: string;
  onImageChange?: (idx: number, image?: App.Image) => void
}

const bookablePropertyResultMessage = {
  [OFFER_TYPE_LAST_MINUTE]: 'We found a last minute deal for your hotel. Book now before it ends!',
  [OFFER_TYPE_ALWAYS_ON]: 'We found a great deal for your hotel. Book now to secure your room!',
}

const bookablePropertyResultChooseDatesMessage = {
  [OFFER_TYPE_LAST_MINUTE]: 'We found a last minute deal for your hotel. Choose dates and book now before it ends!',
  [OFFER_TYPE_ALWAYS_ON]: 'We found a great deal for your hotel. Choose dates and book now to secure your room!',
}

function SearchOfferTile(props: Props) {
  const {
    pricesErrors,
    bestPrices,
    filters,
    passedWalledGarden,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    offerLinkIncludesFilters,
    isSpoofed,
    currentRegion,
    onImageChange,
  } = props

  const offerId = props.offer.id
  const dispatch = useAppDispatch()

  const metaData = useOfferMetaData(offerId, filters)
  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const hasDates = !!(checkIn && checkOut)
  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])
  const impressionRef = useImpressionHandler(offerId)
  const bestPrice = bestPrices?.[searchKey]
  const bestPriceError = pricesErrors[searchKey]
  const useBestPrice = passedWalledGarden && !!(checkIn && checkOut && filters?.rooms)
  const available = bestPrice?.available
  const bestPriceRate = available ? bestPrice.rate : undefined
  const fetchingPrice = useBestPrice && !bestPrice && !bestPriceError
  const soldOut = useBestPrice && !!bestPrice && !available
  const slimOfferTilesVariant: undefined | boolean = useOptimizelyExperiment(OptimizelyExperiments.searchSlimOfferTiles)

  const [offer = props.offer, fetchingOffer] = useOffer<App.Offer>(offerId, {
    requireSummaryOnly: !useBestPrice,
  })
  const [imageLoaded, setImageLoaded] = useState(false)

  const [showSpoofHighlight, spoofMessage] = useMemo(() => {
    let show = false
    if (offer.visibilitySchedules && isSpoofed) {
      Object.entries(offer.visibilitySchedules).forEach(([schedule, value]) => {
        if (!scheduleIsCurrent(value)) {
          if (schedule === 'AU' && currentRegion === 'AU') {
            show = true
          } else if (schedule !== 'AU' && currentRegion !== 'AU') {
            show = true
          }
        }
      })
    }
    return [show, 'This hotel is only visible to staff and available for purchase.']
  }, [currentRegion, isSpoofed, offer.visibilitySchedules])

  const { upsellPackage } =
    useSearchTileInclusionUpsellPackage(offer, filters)

  const showHighlight = filters?.propertyId && !filters?.searchNearby
  let highlightMessage = ''
  let tileType: 'loading' | 'expanded' | 'condensed'

  if (useBestPrice) {
    if (fetchingPrice) {
      tileType = 'loading'
    } else if (available && !bestPriceError) {
      tileType = 'expanded'
      highlightMessage = bookablePropertyResultMessage[offer.type]
    } else {
      tileType = 'condensed'
      highlightMessage = `There are no ${getPlural(offer.saleUnit)} available for your selected dates.`
    }
  } else {
    tileType = 'expanded'
    highlightMessage = passedWalledGarden ?
      bookablePropertyResultChooseDatesMessage[offer.type] :
      bookablePropertyResultMessage[offer.type]
  }

  if (showSpoofHighlight) {
    highlightMessage = spoofMessage
    tileType = 'expanded'
  }

  const bestPricePackage = useMemo(() => {
    if (!useBestPrice || bestPriceError) {
      let bpPackage

      if (offer.hasTactical) {
        const allPackagesFilteredByLowestPrice = (offer.packages ?? []).filter(pkg => pkg.duration === offer.lowestPricePackage?.duration)
        const packagedViews = allPackagesFilteredByLowestPrice.filter(pkg => pkg.roomRate?.isPackaged)

        for (const view of packagedViews) {
          if (view.price > 0) {
            const indexPackageViewByDuration = allPackagesFilteredByLowestPrice.findIndex(x => x.roomType?.id === view.roomType?.id && x.roomRate?.packagedRatePlanId === view.roomRate?.ratePlanId)
            if (indexPackageViewByDuration >= 0) {
              allPackagesFilteredByLowestPrice[indexPackageViewByDuration] = view
            } else {
              allPackagesFilteredByLowestPrice.push(view)
            }
          }
        }

        bpPackage = sortBy(allPackagesFilteredByLowestPrice.filter(pkg => pkg.hasTactical && pkg.price > 0), p => p.price, 'asc')[0]
      }

      return bpPackage || offer.lowestPricePackage
    }

    if (bestPriceRate && !fetchingOffer) {
      const bestPricePkg = offer.packages.find(pkg => pkg.uniqueKey === bestPriceRate.packageUniqueKey)
      if (!bestPricePkg) {
        logNewRelicError('Missing best price package from offer', {
          offerId: offer.id,
          packages: offer.packages.map(pkg => pkg.uniqueKey),
          bestPriceRate,
        })
      }
      return bestPricePkg ?? offer.lowestPricePackage
    }
  }, [offer, bestPriceRate, useBestPrice, bestPriceError, fetchingOffer])

  useEffect(() => {
    const occupanciesInvalid = (filters?.rooms ?? []).length === 0 || (filters?.rooms ?? []).some(item => item.childrenAge?.some(age => age < 0))
    if (useBestPrice && !occupanciesInvalid) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn,
        checkOut,
        occupants: filters.rooms,
      }))
    }
  }, [checkIn, checkOut, dispatch, filters, offer, useBestPrice])

  useOfferSoldOutPushDown(offer.id, filters, !fetchingPrice && soldOut)

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetchingPrice))) {
      onEvent(OfferListEvents.offerReady, {
        available: !(offer.isSoldOut || tileType == 'condensed'),
      })
    }
  }, [fetchingPrice, hasDates, imageLoaded, offer.isSoldOut, onEvent, tileType])

  if (tileType === 'loading') {
    return <SearchOfferTileLoadingSkeleton />
  }

  if (offer.isSoldOut && showHighlight) {
    tileType = 'condensed'
    highlightMessage = `There are no ${getPlural(offer.saleUnit)} available for your selected dates.`
  }

  return <Root type="clean" ref={impressionRef}>
    {showHighlight && <HighlightMessage info={showSpoofHighlight} message={highlightMessage} />}
    {tileType === 'expanded' && !slimOfferTilesVariant && <SearchOfferTileExpanded
      offer={offer}
      offerMetaData={offerMetaData}
      filters={filters}
      bestPrice={bestPriceRate}
      soldOut={soldOut}
      bestPricePackage={bestPricePackage}
      passedWalledGarden={passedWalledGarden}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
      offerLinkIncludesFilters={offerLinkIncludesFilters}
      onImageChange={onImageChange}
      showInclusionUpsell={!!upsellPackage}
      onImageLoad={handleImageLoaded}
    />}
    {tileType === 'expanded' && slimOfferTilesVariant && <SearchOfferTileExpandedSlim
      offer={offer}
      offerMetaData={offerMetaData}
      filters={filters}
      bestPrice={bestPriceRate}
      soldOut={soldOut}
      bestPricePackage={bestPricePackage}
      passedWalledGarden={passedWalledGarden}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
      offerLinkIncludesFilters={offerLinkIncludesFilters}
      onImageChange={onImageChange}
      showInclusionUpsell={!!upsellPackage}
      onImageLoad={handleImageLoaded}
    />}
    {tileType === 'condensed' && <SearchOfferTileCondensed
      offer={offer}
      filters={filters}
      eagerLoadFirstImage={eagerLoadFirstImage}
      onImageChange={onImageChange}
      onImageLoad={handleImageLoaded}
    />}
  </Root>
}

SearchOfferTile.defaultProps = {
  filters: {},
  bestPrices: {},
  pricesErrors: {},
}

function mapStateToProps(state: App.State, ownProps: Partial<Props>) {
  return {
    bestPrices: state.offer.offerBestPrices[ownProps.offer.id],
    pricesErrors: state.offer.offerPricesErrors[ownProps.offer.id],
    passedWalledGarden: hasPassedWalledGarden(state),
    isSpoofed: isSpoofed(state),
    currentRegion: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(SearchOfferTile)
